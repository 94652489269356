/**
 * @typedef { import('./../models/trainingCourses/trainingCourse.model.js').TrainingCourseModel } TrainingCourseModel
 */

/** @typedef {ReturnType<state>} State */
/** @typedef {Record<string, (state: State, data) => void>} Mutation */
export const state = () => ({
  courses: [],
  course: null
});

/**
 * @type {Mutation}
 */
export const mutations = {
  SET_COURSES(state, payload) {
    state.courses = [...payload];
  },
  SET_COURSE_DETAIL(state, payload) {
    state.course = payload;
  },
  DELETE_COURSE(state, deletedCourseId) {
    state.courses = state.courses.filter(cr => cr.id !== deletedCourseId);
  },
};

export const actions = {
  async getCourses({ commit }, params = {}) {
    commit('SET_LOADING', true, { root: true });
    try {
      /** @type {TrainingCourseModel[]} */
      const res = await this.$axios.get(`/trainingCourses`, {params});
      commit('SET_COURSES', res.data);

      return res.data || [];
    } catch (err) {
      commit("user/SHOW_MESSAGE", {
        color: "error",
        message: "Ops, qualcosa è andato storto nel recuperare la lista dei corsi"
      }, { root: true });
    } finally {
      commit('SET_LOADING', false, { root: true });
    }
  },

  async getCourseDetails({ commit }, {id, ...params}) {
    commit('SET_LOADING', true, { root: true });
    try {
      /** @type {TrainingCourseModel} */
      const res = await this.$axios.get(`/trainingCourses/${id}`, {params});
      commit('SET_COURSE_DETAIL', res.data);
      return res.data;

    } catch (err) {
      commit("user/SHOW_MESSAGE", {
        color: "error",
        message: "Ops, qualcosa è andato storto nel recuperare il dettaglio del corso"
      }, { root: true });
      return null;
    } finally {
      commit('SET_LOADING', false, { root: true });
    }
  },

  // New function to get training course events
  async getTrainingCourse({ commit }, payload) {
    try {
      const res = await this.$axios.get(`/trainingCourses/${payload.id}`, { params: payload.params });
      return res.data;
    } catch (err) {
      console.error(err);
      return null;
    }
  },

  clearCourse({commit}) {
    commit('SET_COURSE_DETAIL', null);
  },

  /**
   * Create a course if the given id is null, or update
   * the given one
   */
  async updateCourse({ commit, rootState }, {id, ...course}) {
    commit('SET_LOADING', true, { root: true });
    try {
      /** @type {TrainingCourseModel} */
      const res = await (id == null
        ? this.$axios.post(`/trainingCourses`, course)
        : this.$axios.patch(`/trainingCourses/${id}`, course));

      const courses = rootState.trainingCourses.courses.filter(c => c.id !== id);
      commit('SET_COURSES', [res.data, ...courses]);

      commit("user/SHOW_MESSAGE", {
        color: "success",
        message: `Corso ${id == null ? 'inserito' : 'aggiornato'} con successo`
      }, { root: true });

      return res.data;
    } catch (err) {
      console.error(err);
      return null;
    } finally {
      commit('SET_LOADING', false, { root: true });
    }
  },

  /**
   * Delete a course with the given ID
   * @param {string} courseId
   */
   async deleteCourse({ commit }, courseId) {
    commit('SET_LOADING', true, { root: true });
    try {
      await this.$axios.delete(`/trainingCourses/${courseId}`);
      commit('DELETE_COURSE', courseId);

    } catch (err) {
      commit("user/SHOW_MESSAGE", {
        color: "error",
        message: "Ops, qualcosa è andato storto nel recuperare la lista dei corsi"
      }, { root: true });
    } finally {
      commit('SET_LOADING', false, { root: true });
    }
  },

   async createRecurringSession({ commit }, {courseId, data}) {
     try {
      commit('SET_LOADING', true, { root: true });
      const result = await this.$axios.post(`/trainingCourses/${courseId}/recurringSessions`, data);
      return result.data;
    } catch (err) {
      console.error(err);
      return null;
    } finally {
      commit('SET_LOADING', false, { root: true });
    }
  },
  async updateRecurringSession({ commit }, {courseId, recurringSessionId, data, clearAll}) {
    try {
     commit('SET_LOADING', true, { root: true });
     const result = await this.$axios.patch(`/trainingCourses/${courseId}/recurringSessions/${recurringSessionId}`, {...data, clearAll});
     return result.data;
   } catch (err) {
     console.error(err);
     return null;
   } finally {
     commit('SET_LOADING', false, { root: true });
   }
  },
  async getRecurringSession({ commit }, {recurringSessionId, ...params}) {
    try {
     commit('SET_LOADING', true, { root: true });
     const result = await this.$axios.get(`/recurringSessions/${recurringSessionId}`, {params});
     return result.data;
   } catch (err) {
     console.error(err);
     return null;
   } finally {
     commit('SET_LOADING', false, { root: true });
   }
  },
  async updateRecurringParticipants({ commit }, {courseId, recurringSessionId, data}) {
    try {
     commit('SET_LOADING', true, { root: true });
     const result = await this.$axios.post(`/trainingCourses/${courseId}/recurringSessions/${recurringSessionId}/updateParticipants`, data);
     return result.data;
   } catch (err) {
     console.error(err);
     return null;
   } finally {
     commit('SET_LOADING', false, { root: true });
   }
  },
  async publishRecurringSession({ commit }, {courseId, recurringSessionId, data}) {
    try {
      commit('SET_LOADING', true, { root: true });
      const result = await this.$axios.patch(`/trainingCourses/${courseId}/recurringSessions/${recurringSessionId}/publish`, {...data, status: 'published' });
      return result.data;
    } catch (err) {
      console.error(err);
      return null;
    } finally {
      commit('SET_LOADING', false, { root: true });
    }
  },
   async deleteRecurringSession({ commit, state }, {courseId, recurringSessionId}) {
     try {
      commit('SET_LOADING', true, { root: true });
      await this.$axios.delete(`/trainingCourses/${courseId}/recurringSessions/${recurringSessionId}`);

      if(state.course?.id === courseId){
        commit('SET_COURSE_DETAIL', {
          ...state.course,
          recurringSessions: state.course.recurringSessions.filter(s => s.id !== recurringSessionId)
        })
      }
      return true;
    } catch (err) {
      console.error(err);
      return false;
    } finally {
      commit('SET_LOADING', false, { root: true });
    }
  },
};
