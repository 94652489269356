export const state = () => ({
    sites: [],
    site: {},
});

export const getters = {}

export const mutations = {
    SET_SITES(state, payload) {
        state.sites = payload;
    },
    SET_SITE(state, payload) {
        state.site = payload;
    },
    ADD_SITE(state, payload) {
        state.sites.push(payload)
    },
    PATCH_SITE(state, payload) {
        let sites = state.sites;
        let editedSite = sites.findIndex(s => s.id === payload.id);
        sites[editedSite] = Object.assign({}, payload);
        this.commit('sites/SET_SITES', sites);
    },
    DELETE_SITE(state, index) {
        state.sites.splice(index, 1);
    }
};

export const actions = {
    // @deprecated
    async getMySites({ commit, rootState }, filter) {
        let qs = ''
        if (filter) {
            qs = `?${filter.property}=${filter.value}`
        }
        commit('SET_LOADING', true, { root: true });

        try {
            const res = await this.$axios.get(`/sites${qs}`, {
                headers: {
                    Authorization: "Bearer " + rootState.localStorage.bearer,
                }
            });
            commit('SET_SITES', res.data);
            return res.data;
        } catch (err) {
            console.warn("Something went wrong while fetching this promoter's sites", err),
                commit("user/SHOW_MESSAGE", {
                    color: "error",
                    message: "Ops, qualcosa è andato storto nel reperire le tue sedi",
                    show: true,
                    timeout: 3000
                }, { root: true });
        }

        commit('SET_LOADING', false, { root: true });
    },

    async getSites({ commit, rootState }, params) {
        try {
            commit('SET_LOADING', true, { root: true });
            const res = await this.$axios.get(`/sites`, {params});
            commit('SET_SITES', res.data);
            return res.data;
        } finally {
            commit('SET_LOADING', false, { root: true });
        }

    },

    getMyOpenSites({ dispatch }) {
      return dispatch("getMySites", {
        property: 'status',
        value: 'open'
      });
    },

    async getSiteById({ commit, rootState }, siteId) {
        commit('SET_LOADING', true, { root: true });

        try {
            const res = await this.$axios.get(`/sites/${siteId}`, {
                headers: {
                    Authorization: "Bearer " + rootState.localStorage.bearer,
                }
            });
            commit('SET_SITE', res.data);
        } catch (err) {
            console.warn("Something went wrong while fetching this promoter's site", err),
                commit("user/SHOW_MESSAGE", {
                    color: "error",
                    message: "Ops, qualcosa è andato storto nel reperire questa sede",
                    show: true,
                    timeout: 3000
                }, { root: true });
        }

        commit('SET_LOADING', false, { root: true });
    },

    async createSite({ commit, rootState }, data) {
        commit('SET_LOADING', true, { root: true });

        try {
            const res = await this.$axios.post('/sites', data, {
                headers: {
                    Authorization: "Bearer " + rootState.localStorage.bearer,
                }
            });
            commit('ADD_SITE', res.data);
            return res.data;
        } catch (err) {
            console.warn("Something went wrong while fetching this promoter's sites", err),
                commit("user/SHOW_MESSAGE", {
                    color: "error",
                    message: "Ops, qualcosa è andato storto nel reperire le tue sedi",
                    show: true,
                    timeout: 3000
                }, { root: true });
        }

        commit('SET_LOADING', false, { root: true });
        return false;
    },

    async patchSite({ commit, rootState }, data) {
        let localObj = JSON.parse(JSON.stringify(data));
        const siteID = localObj.id
        delete localObj.id
        delete localObj.promoterID
        commit('SET_LOADING', true, { root: true });

        try {
            const res = await this.$axios.patch(`/sites/${siteID}`, localObj, {
                headers: {
                    Authorization: "Bearer " + rootState.localStorage.bearer,
                }
            });
            commit('PATCH_SITE', res.data);
            commit('SET_LOADING', false, { root: true });
            return true;
        } catch (err) {
            console.warn("Something went wrong while editing this promoter's site", err),
                commit("user/SHOW_MESSAGE", {
                    color: "error",
                    message: "Ops, qualcosa è andato storto nel modificare questa sede",
                    show: true,
                    timeout: 3000
                }, { root: true });
            commit('SET_LOADING', false, { root: true });
            return false;
        }

    },

    async deleteSite({ commit, rootState }, data) {
        commit('SET_LOADING', true, { root: true });
        try {
            const res = await this.$axios.post(`/sites/${data.id}/close`, null, {
                headers: {
                    Authorization: "Bearer " + rootState.localStorage.bearer,
                }
            });
            commit('PATCH_SITE', res.data);
            commit("user/SHOW_MESSAGE", {
                color: "success",
                message: "Abbiamo cancellato la tua sede con successo",
                show: true,
                timeout: 3000
            }, { root: true });
            commit('SET_LOADING', false, { root: true });
            return true;
        } catch (err) {
            console.warn("Something went wrong while deleting this promoter's site", err),
                commit("user/SHOW_MESSAGE", {
                    color: "error",
                    message: "Ops, qualcosa è andato storto nel cancellare questa sede",
                    show: true,
                    timeout: 3000
                }, { root: true });
        }

        commit('SET_LOADING', false, { root: true });
        return false;
    },

    async reorderSites({ commit, rootState }, data) {
        try {
            await this.$axios.post(`/sites/reorder`, data);
        } catch (err) {
            console.warn("Something went wrong while reordering this promoter's sites", err),
            commit("user/SHOW_MESSAGE", {
                color: "error",
                message: "Something went wrong while reordering this promoter's sites",
                show: true,
                timeout: 3000
            }, { root: true });
        }
        return false;
    },

};
