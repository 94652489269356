
const dateFormat = 'YYYY-MM-DD';
const staticAvailabilityOptions = Object.freeze([
  { divider: true, },
  { id: 'none', name: "components.timeRangeAvailability.noFilter", },
  { id: "custom", name: "components.timeRangeAvailability.customChoice", },
]);

export default {
  props: {
    disabled: Boolean,
    value: {
      type: Object,
    },
    title: {
      type: String,
      default: 'components.timeRangeAvailability.packageValidity'
    },
    label: {
      type: String,
      default: null
    },
    timeFramesList: {
      type: Array,
      default: () => []
    },
    hideTitle: {
      type: Boolean,
      default: false
    },
    fullWidth: {
      type: Boolean,
      default: false
    },
    openAsPopup: {
      type: Boolean,
      default: false
    },
    showSelectedRangeParagraph: {
      type: Boolean,
      default: true
    },
    hideNoFilter: Boolean,
    minDate: {
      type: String,
      default: null
    },
    maxDate: {
      type: String,
      default: null
    },
    showFeedbackLabel: {
      type: Boolean,
      default: false
    }
  },
  setup(){
    return {
    }
  },
  data(){
    return {
      startAt__ShowPicker: false,
      endAt__ShowPicker: false,
      startAt__PickerValue: null,
      endAt__PickerValue: null,
      selectedTimeRange: this.value,
      rules: {
        required: (v) => v != null && v != '' || this.$t('validationRules.required')
      },
    }
  },
  computed: {
    localizedLabel(){
      if (!this.label) return this.$t('components.timeRangeAvailability.selectRangeType');
      return this.label;
    },
    availabilityTimeRanges() {
      const staticItems = staticAvailabilityOptions.filter(o => o.id !== 'none' || !this.hideNoFilter);
      return [...this.timeFramesList, ...staticItems];
    },
    selectedTimeRangeInterval(){
      if(!this.selectedTimeRange) return '';

      const format = 'dd MMMM yyyy';
      const {startAt, endAt} = this.selectedTimeRange;

      if(!startAt || !endAt) return '';

      const firstDate = this.$DateTime.fromISO(startAt);
      const secondDate = this.$DateTime.fromISO(endAt);

      return this.$t('components.timeRangeAvailability.fromTo', {
        from: firstDate.toFormat(format),
        to: secondDate.toFormat(format)
      });
    },
  },
  watch: {
    value(newValue = {}) {
      const timeFrame = this.timeFramesList.find(tf => tf.id === newValue?.id);
      this.selectedTimeRange = timeFrame ?? {id: "custom", ...newValue};
    },

  },
  methods: {
    handleDatePickerChange(){
      if(this.startAt__PickerValue){
        this.selectedTimeRange['startAt'] = this.$moment(this.startAt__PickerValue, dateFormat).startOf('day').toISOString();
      }

      if(this.endAt__PickerValue){
        this.selectedTimeRange['endAt'] = this.$moment(this.endAt__PickerValue, dateFormat).endOf('day').toISOString();
      }

      this.handleInput();
    },
    handleInput(skipModal = false){
      this.$emit('input', {...this.selectedTimeRange, skipModal})
    }
  },
  mounted(){
    if(!this.value) {
      this.selectedTimeRange = { id: "none" };
      const timeRangeAvailability = sessionStorage.getItem("timeRangeAvailability");
      if(timeRangeAvailability){
        const {id, ...timeFrame} = JSON.parse(timeRangeAvailability);
        const existingTimeFrame = this.timeFramesList.find(tf => tf.id === id);

        const timeFrameObj = this.$cp(existingTimeFrame ?? {id: "custom", ...timeFrame});

        this.selectedTimeRange = timeFrameObj;
        this.handleInput(true);
      }
    }
  }
}
