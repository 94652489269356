import { render, staticRenderFns } from "./default.vue?vue&type=template&id=3b997d79&scoped=true"
import script from "./default.vue?vue&type=script&lang=js"
export * from "./default.vue?vue&type=script&lang=js"
import style0 from "./default.vue?vue&type=style&index=0&id=3b997d79&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/.pnpm/vue-loader@15.11.1_cache-loader@4.1.0_webpack@4.47.0__css-loader@5.2.7_webpack@4.47.0___d9816230dbea7e104690bfe250ed4381/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b997d79",
  null
  
)

export default component.exports