export const state = () => ({
  reservations: [],
});

export const getters = {}

export const mutations = {
  SET_RESERVATIONS(state, payload) {
    state.reservations = payload;
  },
};

export const actions = {
  async fetchReservations({ commit, rootState }, params) {
    try {
      const res = await this.$axios.get('/reservations', {
        params,
      });
      commit('SET_RESERVATIONS', res.data.reservations);
      return res.data;
    } catch (err) {
      commit("user/SHOW_MESSAGE", {
        color: "error",
        message: "Qualcosa è andato storto nel recuperare le prenotazioni",
        show: true,
        timeout: 3000,
      }, { root: true });
      console.warn("Something went wrong with reservations", err)
    }
  },
  async fetchReservation({ commit }, id) {
    try {
      const res = await this.$axios.get(`/v2/reservations/${id}`);
      const data = res.data;
      formatReservation(data);
      return data;
    } catch (err) {
      console.error(err);
      return null;
    }
  },
  async createReservation({ commit }, data) {
    const params = data.params;
    const reservation = data.reservation;
    try {
      const res = await this.$axios.post(`/v2/reservations`, reservation);
      formatReservation(res.data);
      return res;
    } catch (err) {
      return err.response.data;
    }
  },
  /**
   * Uses the v1 of the api, this method performs all the checks and updates the reservation
   * @param {*} param0 
   * @param {*} data 
   * @returns 
   */
  async updateReservation({ commit }, data) {
    const params = data.params;
    const reservation = data.reservation;
    try {
      const res = await this.$axios.put(`/reservations/${reservation.id}`, reservation);
      formatReservation(res.data);
      return res;
    } catch (err) {
      return err.response.data;
    }
  },
  /**
   * Uses the v2 of the api, this method only apply the given updates
   * @param {*} param0 
   * @param {*} data 
   * @returns 
   */
  async patchReservation({ commit }, { notes, status, id }) {
    try {
      const res = await this.$axios.patch(`/v2/reservations/${id}`, { notes, status });
      formatReservation(res.data);
      return res;
    } catch (err) {
      return err.response.data;
    }
  },
  async confirmReservation({ commit }, reservation) {
    try {
      const res = await this.$axios.post(`/reservations/${reservation.id}/confirmations`);
      formatReservation(res.data);
      return res;
    } catch (err) {
      console.log("Something went wrong with the reservation confirmation", err)
      commit("user/SHOW_MESSAGE", {
        color: "error",
        message: "Qualcosa è andato storto nel confermare la tua prenotazione. Controlla che non ci siano sovrapposizioni con altre prenotazioni confermate",
        show: true,
        timeout: 3000,
      }, { root: true })
      return null;
    }
  },
  async cancelReservation({ commit }, { reservation, target = undefined }) {
    try {
      const res = await this.$axios.delete(`/reservations/${reservation.id}`, {
        data: {
          target,
        }
      });
      return res;
    } catch (err) {
      console.log("Something went wrong with the reservation cancellation", err)
      commit("user/SHOW_MESSAGE", {
        color: "error",
        message: "Qualcosa è andato storto nel cancellare la tua prenotazione",
        show: true,
        timeout: 3000,
      }, { root: true })
      return null;
    }
  },
  async putReservationNotes({ commit }, payload) {
    try {
      const res = await this.$axios.put(`/reservations/${payload.reservationId}/notes`, payload.body);
      return res;
    } catch (err) {
      console.log("Something went wrong with the location put", err)
      commit("user/SHOW_MESSAGE", {
        color: "error",
        message: "Qualcosa è andato storto nel modificare la tua prenotazione",
        show: true,
        timeout: 3000,
      }, { root: true })
      return null;
    }
  },
  async payReservation({ commit }, payload) {
    try {
      const res = await this.$axios.post(`/v2/reservations/${payload.id}/payments`, payload);
      formatReservation(res.data);
      return res;
    } catch (err) {
      return err.response.data;
    }
  },
};

/**
 * This function formats the reservation to be used in the store,
 * I know its ugly and we should refactor the UI to know how to format prices properly.
 * TODO: Leo 10/03/2025 - Refactor the UI to know how to format prices properly
 * @param {*} reservation 
 */
function formatReservation(reservation) {
  if (reservation.debit?.price) {
    reservation.debit.price.total = reservation.debit.price.total / 100;
    reservation.debit.price.discounted = reservation.debit.price.discounted / 100;
    reservation.debit.price.paid = reservation.debit.price.paid / 100;
    reservation.debit.price.toPay = reservation.debit.price.toPay / 100;
  }
  if (reservation.debit?.payments?.length) {
    reservation.debit.payments.forEach((payment) => {
      payment.amount = payment.amount / 100;
    });
  }
}